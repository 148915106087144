import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../../components/partials/HeaderSEO'
import { Helmet } from 'react-helmet'
import Layout from '../../../components/Layout'
import Spacing from '../../../components/partials/Spacing'

import gsap from 'gsap'
import { StaticImage } from 'gatsby-plugin-image'
// Using images instead of StaticImage to
// preserve top quality
import pd_message from '../../../resources/images/kafka_guide/intro/pd_message.svg'
import Loadable from '@loadable/component'

const LoadableIntro = Loadable(
  () => import('../../../components/monitoringGuide/RestOfIntro')
)

const intro = ({ transitionStatus }) => {
  const triggerAnimationsExit = () => {
    gsap.to('.Intro', { x: -window.innerWidth, duration: 0.83 })
    gsap.fromTo('.Chapter1', { x: window.innerWidth }, { x: 0, duration: 0.83 })
    gsap.fromTo('.Chapter2', { x: window.innerWidth }, { x: 0, duration: 0.83 })
    gsap.fromTo('.Chapter3', { x: window.innerWidth }, { x: 0, duration: 0.83 })
  }

  useEffect(() => {
    if (transitionStatus === 'exiting') {
      // Scrolling top to prevent flickering
      window.scrollTo(0, 0)
      triggerAnimationsExit()
    }
  }, [transitionStatus]) //execute when transitionStatus change
  const data = useStaticQuery(graphql`
    query Kafka_Guide_Intro {
      allContentfulPages(
        filter: {
          pageReference: { pageIdentifier: { eq: "Kafka_Guide_Form_Page" } }
        }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  useEffect(() => {
    // Set here to Prevent doubleScrollbar
    document.querySelector<HTMLElement>('.tl-edges').style.overflowX = 'hidden'
    setTimeout(() => {
      // Update style here to prevent flicker effect
      document.querySelector<HTMLElement>('.intro').style.color = '#ffcf42'
      document.querySelector<HTMLElement>('.text-p1').style.color = '#fff'
      document.querySelector<HTMLElement>('.text-p2').style.color = '#fff'
      document.querySelector<HTMLElement>('.text-p3').style.color = '#fff'
      document.querySelector<HTMLElement>('.text-p4').style.color = '#fff'
      document.querySelector<HTMLElement>('.text-p5').style.color = '#fff'
      document
        .querySelector<HTMLImageElement>('.img-1')
        .classList.remove('d-none')
      document
        .querySelector<HTMLImageElement>('.img-2')
        .classList.remove('d-none')
    }, 100)
  }, [])

  return (
    <Layout type="KafkaMonitoring" noFooter={true}>
      <HeaderSEO pageSEO={pageSEO} />
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta name="robots" content="nofollow" />
      </Helmet>
      <div>
        <section className="kafka-monitoring-intro-1">
          <div className="pt-5 mt-5 container-2 Intro">
            <div className="d-flex flex-wrap align-items-end">
              <div className="col-lg-5 col-md-9 col-sm-10 col-11">
                <h2 className="intro kafka-monitoring-intro-1-text f-64 fw-900">
                  <div className="d-sm-block d-lg-none">
                    <Spacing />
                  </div>
                  The human cost of Kafka monitoring
                </h2>
                <p className="kafka-monitoring-intro-2-text f-24 lh-1-3-6 text-p1">
                  It’s a Friday evening work cocktail night. Terry, a developer
                  from the eCommerce team (that wasn’t invited for reasons we
                  shall not divulge), messages you with a regular request. All
                  his messages are “urgent” except this one doesn’t even have a
                  “hi”: Can you check if this specific message has been
                  published into Kafka?
                </p>
                <Spacing />
              </div>
              <div className="col-lg-7 col-md-9 col-12 mobile-margin-top">
                <div className="text-white">
                  <div className="d-sm-none d-lg-block">
                    <Spacing />
                  </div>

                  <StaticImage
                    className="img-1 d-none img-fluid"
                    quality={100}
                    src="../../../resources/images/kafka_guide/intro/intro_hero.svg"
                    placeholder="tracedSVG"
                    alt="Human Cost of Kafka Monitoring"
                  />
                </div>
              </div>
              <Spacing />
            </div>

            <div className="d-flex flex-wrap justify-content-sm-center pt-5">
              <div className="col-lg-6 col-md-7 col-sm-8 col-11">
                <p className="kafka-monitoring-intro-2-text f-18 lh-26 text-p2">
                  You would think (incorrectly) that Terry could do this
                  himself. Yet, since the eCommerce authentication for the
                  website was re-architected around Kafka, you alone are the
                  data observability solution, supporting more than 50
                  developers.
                </p>

                <p className="kafka-monitoring-intro-2-text f-18 lh-26 text-p3">
                  Priya, the SRE, has just received a PagerDuty alert, escalated
                  from the support desk and the Head of eCommerce. She’s
                  standing next to you.
                </p>
                <p className="kafka-monitoring-intro-2-text f-18 lh-26 text-p4">
                  It’s going to be a long night, and not on the cocktails.
                </p>

                <p className="kafka-monitoring-intro-2-text f-18 lh-26 text-p5">
                  An extra hour or two to troubleshoot isn’t the end of the
                  world when Kafka is a pilot project engineered by a couple of
                  Kafka connoisseurs. But when you have a new, critical
                  application - like Terry’s new authentication microservice -
                  the data you need to monitor your platform, and who has access
                  to it, has to change.
                </p>
              </div>

              <div className="col-lg-8 col-md-10 col-sm-12 offset-0 offset-md-2 col-12 mobile-margin-bottom mobile-margin-top">
                <div className="text-white text-center mt-4">
                  <img
                    className="img-2 d-none img-fluid"
                    src={pd_message}
                    alt="PD message"
                  />
                </div>
              </div>
            </div>
          </div>
          <Spacing />
        </section>

        <LoadableIntro />
      </div>
    </Layout>
  )
}

export default intro
